import React from 'react';
import { IonIcon } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';

interface UpdateButtonProps {
    onUpdate: () => void;
    isVisible: boolean;
    message?: string;
}

const UpdateAvailableButton: React.FC<UpdateButtonProps> = ({ onUpdate, isVisible, message = 'Update available - Click to refresh' }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div
            onClick={onUpdate}
            style={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px',
                padding: '10px',
                backgroundColor: 'rgba(0, 123, 255, 0.1)',
                border: '1px solid #007bff',
                borderRadius: '5px',
                cursor: 'pointer'
            }}
        >
            <IonIcon icon={refreshOutline} style={{ flexShrink: 0, width: '30px', height: '30px', marginRight: '10px' }} />
            <span>{message}</span>
        </div>
    );
};

export default UpdateAvailableButton;
