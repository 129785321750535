import React, {useEffect, useRef} from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'chartjs-adapter-moment';

interface LineChartProps {
    lobby: LobbyData;
}

interface LobbyData {
    moves: Move[];
}

interface Move {
    player: {
        name: string;
    };
    deltaGulps: number;
    moveDate: string;
}

const LineChartComponent: React.FC<LineChartProps> = ({ lobby }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext('2d');

        if (ctx) {
            const playerData: { [player: string]: { x: moment.Moment; y: number }[] } = {};

            lobby.moves.forEach((move) => {
                if (move.deltaGulps === 0) {
                    return;
                }

                const player = move.player.name;
                const moveDate = moment(move.moveDate);
                const deltaGulps = move.deltaGulps;

                if (!playerData[player]) {
                    playerData[player] = [];
                }

                const previousTotal = playerData[player].length > 0 ? playerData[player][playerData[player].length - 1].y : 0;
                const currentTotal = previousTotal + deltaGulps;

                playerData[player].push({ x: moveDate, y: currentTotal });
            });

            const playerColors = ['red', 'blue', 'green', 'purple', 'orange', 'pink', 'yellow', 'cyan'];

            const datasets = Object.keys(playerData).map((player, index) => ({
                label: player,
                data: playerData[player],
                backgroundColor: playerColors[index % playerColors.length],
                borderColor: playerColors[index % playerColors.length],
                borderWidth: 1,
            }));

            const chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: datasets,
                },
                options: {
                    animation: false,
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'minute',
                            },
                            ticks: {
                                source: 'auto',
                            },
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });

            return () => {
                chart.destroy();
            };
        }
    }, [lobby]);

    return <canvas ref={chartRef} />;
};

export default LineChartComponent;
