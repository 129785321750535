    import React, {useEffect, useRef} from 'react';
    import Chart from 'chart.js/auto';
    import 'chartjs-adapter-moment';

    interface LineChartProps {
        data: { deltaGulps: number, date: string, playerName: string }[];
    }

    const LineChartTotals: React.FC<LineChartProps> = ({ data }) => {
        const chartRef = useRef<HTMLCanvasElement>(null);

        useEffect(() => {
            const ctx = chartRef.current?.getContext('2d');

            if (ctx) {
                const playerData: { [player: string]: { x: string; y: number }[] } = {};

                data.forEach((move) => {
                    const player = move.playerName;
                    const moveDate = move.date;
                    const deltaGulps = move.deltaGulps;

                    if (!playerData[player]) {
                        playerData[player] = [];
                    }

                    const previousTotal = playerData[player].length > 0 ? playerData[player][playerData[player].length - 1].y : 0;
                    const currentTotal = previousTotal + deltaGulps;

                    playerData[player].push({ x: moveDate, y: currentTotal });
                });

                const playerColors = ['red', 'blue', 'green', 'purple', 'orange', 'pink', 'yellow', 'cyan'];

                const datasets = Object.keys(playerData).map((player, index) => ({
                    label: player,
                    data: playerData[player],
                    backgroundColor: playerColors[index % playerColors.length],
                    borderColor: playerColors[index % playerColors.length],
                    borderWidth: 1,
                }));

                const chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        datasets: datasets,
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: false,
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'day',
                                    parser: 'YYYY-MM-DD',
                                    tooltipFormat: 'll'
                                },
                            },
                            y: {
                                beginAtZero: true,
                            },
                        },
                    },
                });

                return () => {
                    chart.destroy();
                };
            }
        }, [data]);

        return <canvas ref={chartRef} />;
    };

    export default LineChartTotals;
