import {IonCard, IonCardContent, IonCardHeader, IonList} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import createAxiosInstance from "../services/AxiosInstance";
import io from "socket.io-client";
import "./NewDashboard.css"
import LineChartComponent from "./LineChartComponent";
import LineChartTotals from "./LineChartTotals";
import QRCodeComponent from "./QRCodeComponent";

const NewDashboard: React.FC = () => {
    const [guests, setGuests] = useState<any[]>([]);
    const [eventMessages, setEventMessages] = useState<any[]>([]);
    const axios = createAxiosInstance();
    const [lobbyData, setLobbyData] = useState<any>(null);
    const [totals, setTotals] = useState<any>(null);
    const [amountOfDoublers, setAmountOfDoublers] = useState<number>(0);
    const [amountOfStreets, setAmountOfStreets] = useState<number>(0);
    const [slowestThrower, setSlowestThrower] = useState<any>(null)
    const [fastestThrower, setFastestThrower] = useState<any>(null)
    const [avgAmountOfTurns, setAvgAmountOfTurns] = useState<any>(null)
    const qrValueRef = useRef<string>('');
    const lobbyIdRef = useRef<string>('');

    function fetchPresentGuests() {
        axios.get('/evening/present-guests')
            .then(res => {
                if (res.data[0] !== undefined) {
                    setGuests(res.data)
                    console.log(res.data)
                }
            })
    }

    const fetchLobbyData = async () => {
        const idToUse = lobbyIdRef.current !== '' ? lobbyIdRef.current : null;
        if (idToUse) {
            try {
                await axios.get(`/dertigen/${idToUse}/graph`)
                    .then(res => {
                        setLobbyData(res.data.lobby)
                        setAmountOfDoublers(res.data.amountOfDoublers)
                        setAmountOfStreets(res.data.amountOfStreetThrows)
                        setSlowestThrower(res.data.slowestThrower)
                        setFastestThrower(res.data.fastestThrower)
                        setAvgAmountOfTurns(res.data.avgAmountOfThrows)
                    }).catch(err => {
                        console.error(err)
                    })
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                await axios.get('/dertigen/latest')
                    .then(res => {
                        setLobbyData(res.data.lobby)
                        setAmountOfDoublers(res.data.amountOfDoublers)
                        setAmountOfStreets(res.data.amountOfStreetThrows)
                        setSlowestThrower(res.data.slowestThrower)
                        setFastestThrower(res.data.fastestThrower)
                        setAvgAmountOfTurns(res.data.avgAmountOfThrows)
                        console.log(res.data)
                    }).catch(err => {
                        console.error(err)
                    })
            } catch (error) {
                console.error(error);
            }
        }
    };

    const fetchTotalsData = async () => {
        try {
            const response = await axios.get('/dertigen/total');
            setTotals(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_SOCKET}`);
        fetchPresentGuests()
        fetchLobbyData()
        fetchTotalsData()
        socket.on("connect", () => {
            socket.emit('lobby', 'dashboard')
        })

        socket.on('id', (message) => {
            console.log(message)
            qrValueRef.current = message;

            socket.on(`dashboard-${qrValueRef.current}`, (lobbyId: string) => {
                lobbyIdRef.current = lobbyId;
                fetchLobbyData()
            });
        })

        socket.on('event', (message) => {
            fetchPresentGuests()
            const now: Date = new Date();
            const hours: number = now.getHours();
            const minutes: number = now.getMinutes();

            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            const newEvent = {
                message: message,
                time: `${formattedHours}:${formattedMinutes}`
            };

            setEventMessages(prevMessages => [newEvent, ...prevMessages].slice(0, 10));
        })

        socket.on('refresh', () => {
            fetchLobbyData()
        })

        return () => {
            socket.disconnect()
            socket.off('connect');
            socket.off("disconnect")
            socket.off('event');
        }
    }, [])


    return (
        <>
            <div className="stories-container">
                {guests.length === 0 ? (
                    <p className="no-guests">Er is nog niemand ingecheckt.</p>
                ) : (
                    guests.map((user) => (
                        <div key={user.id} className="story">
                            <div
                                className={`profile-picture ${
                                    user.saldo < 0 ? "negative" : "positive"
                                }`}
                            >
                                <img
                                    src={
                                        user.profilePic !== undefined
                                            ? `${process.env.REACT_APP_STORAGE}${user.profilePic}`
                                            : `${process.env.REACT_APP_STORAGE}default-profile.jpg`
                                    }
                                    alt={user.name}
                                />
                            </div>
                            <p className="username">{user.name}</p>
                        </div>
                    ))
                )}
            </div>
            <div className="dash-container">
                <div className="row-stats-feed">
                    <div className="stats-container visuals">
                        <IonCard className="visual-card qr-card">
                            <IonCardHeader>
                                <h3>Lobby koppelen dashboard</h3>
                            </IonCardHeader>
                            <IonCardContent>
                                {qrValueRef.current && <QRCodeComponent value={qrValueRef.current}/>}
                            </IonCardContent>
                        </IonCard>

                        <IonCard className="visual-card stat-card">
                            <IonCardHeader>
                                <div className="stat-header">
                                    <h3>Verdubbelaars</h3>
                                </div>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2>{amountOfDoublers}</h2>
                                <p className="stat-subtext">Verdubbelaars in huidige lobby</p>
                            </IonCardContent>
                        </IonCard>

                        <IonCard className="visual-card stat-card">
                            <IonCardHeader>
                                <div className="stat-header">
                                    <h3>Straatjes</h3>
                                </div>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2>{amountOfStreets}</h2>
                                <p className="stat-subtext">Straatjes in huidige lobby</p>
                            </IonCardContent>
                        </IonCard>

                        <IonCard className="visual-card stat-card">
                            <IonCardHeader>
                                <div className="stat-header">
                                    <h3>Langzaamste gooier</h3>
                                </div>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2>{slowestThrower?.player}</h2>
                                <p className="stat-subtext">Gemiddeld {(slowestThrower?.avgTime / 1000).toFixed(1)} seconden per worp</p>
                            </IonCardContent>
                        </IonCard>
                        <IonCard className="visual-card stat-card">
                            <IonCardHeader>
                                <div className="stat-header">
                                    <h3>Snelste gooier</h3>
                                </div>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2>{fastestThrower?.player}</h2>
                                <p className="stat-subtext">Gemiddeld {(fastestThrower?.avgTime / 1000).toFixed(1)} seconden per worp</p>
                            </IonCardContent>
                        </IonCard>
                        <IonCard className="visual-card stat-card">
                            <IonCardHeader>
                                <div className="stat-header">
                                    <h3>Gemiddelde beurten</h3>
                                </div>
                            </IonCardHeader>
                            <IonCardContent>
                                <h2>{avgAmountOfTurns}</h2>
                                <p className="stat-subtext">Gemiddeld aantal beurten per speler</p>
                            </IonCardContent>
                        </IonCard>
                    </div>
                    <div className="stats-container feed-container">
                        <IonList class="feed-column">
                            {eventMessages.length === 0 && <p className="no-events">(Nog) geen events gevonden</p>}
                            {eventMessages.map((chatMessage, index) => (
                                <IonCard className="event-card" key={index}>
                                    <h2>{chatMessage.message}</h2>
                                    <h2>{chatMessage.time}</h2>
                                </IonCard>
                            ))}
                        </IonList>
                    </div>
                </div>


                <div className={'row-chart'}>
                    {lobbyData && (
                        <div className='chart'>
                            <LineChartComponent lobby={lobbyData}/>
                        </div>
                    )}
                    {totals && (
                        <div className='chart'>
                            <LineChartTotals data={totals}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default NewDashboard;
